import React, {useState, useEffect, useContext, Suspense, lazy} from 'react';
import Axios from 'axios';
import _ from "lodash";
//import moment from "moment";
import {LPHandlerProvider, LPHandlerContext} from './context/LPHandlerProvider'

import TempSelector from "./temps/TempSelector";

var slugv = "";
if (window.location.pathname.length > 0) {
    slugv = window.location.pathname;
    slugv = slugv.substr(slugv.lastIndexOf('/') + 1);
}

const dataVals = {
    domain: window.location.host,
    slug: slugv
};

const slugT = window.location.pathname;
const slugTR = slugT.substr(slugT.lastIndexOf('/') + 1);

const slugarr = window.location.pathname.substr(1).split("/");
/////console.log(slugarr);


const App = () => {
	
    const [client_id, setClientId] = useState(0);
    const [temp_name, setTempName] = useState("");
    const [lp_id, setLPId] = useState(0);
	const [widget_temps, setWidgetTemps] = useState(0);
	
	// //console.log(dataVals);
	
	//const LpHandler = useContext(LPHandlerContext);
	////console.log(LpHandler);
	
    useEffect(() => {
	
		////console.log(LpHandler);
	
        ////console.log("D2 - "+moment().format('MMMM Do YYYY, h:mm:ss a'));
        Axios.post(window.apiPath + 'getlpinfo', dataVals, window.configJson, {withCredentials: true})
            .then(response => {
				//console.log(response);
                if (response.data) {
                    localStorage.setItem("client_id", JSON.stringify(response.data.client_id));
                    localStorage.setItem("temp_name", JSON.stringify(_.shuffle(response.data.temp_name)[0]));
                    localStorage.setItem("lp_id", JSON.stringify(response.data.lp_id));
					localStorage.setItem("widget_temps", JSON.stringify(response.data.lp_selected_widget_temps));
                    localStorage.setItem("pg_type", JSON.stringify(response.data.pg_type));

                    let temp_name = "";
                    if (slugTR === 'login') {
                        temp_name = 'Login';
                    } else if (slugTR === 'contactme') {
                        temp_name = 'Contact_Card';
                    } else {
                        temp_name = _.shuffle(response.data.temp_name)[0];
                    }

                    setTempName(temp_name);
                    setLPId(response.data.lp_id);
                    setClientId(response.data.client_id);
					
					if (response.data.lp_selected_widget_temps && response.data.lp_selected_widget_temps.length) {
						setWidgetTemps(parseInt(response.data.lp_selected_widget_temps.toString()));
					}
					
                    ////console.log("D3 - "+moment().format('MMMM Do YYYY, h:mm:ss a'));
                }
            })
            .catch(error => {
                console.log(error.response);
            });
    }, []);

   if (temp_name === '') {
        return (
            <Suspense fallback={<div className="App">Loading...</div>}></Suspense>
        );
    } else {
       return (
           <Suspense fallback={<div className="App">Loading...</div>}>
               <div className="App">
                   <LPHandlerProvider>
                       <TempSelector />
                   </LPHandlerProvider>
               </div>
           </Suspense>
       );
   }
}

export default App;
